.alert-shown {
  .navbar {
    margin-top: 60px;
  }
  .app-container {
    margin-top: 130px;
  }
  .aside {
    margin-top: 60px;
    height: calc(100% - 60px);
  }
  &.hide {
    .navbar {
      margin-top: 0;
    }
    .app-container {
      margin-top: 70px;
    }
    .aside {
      margin-top: 0;
      height: 100%;
    }
  }
}

.alert {
  border-radius: $border-radius-lg;
  line-height: $alert-line-height;
  color: $alert-color;
  font-size: $alert-font-size;
  text-align: left;
  .icon-alert {
    margin-right: 0.75rem;
  }
}

.alert-danger {
  background-color: $alert-danger-bg;
  border-color: $alert-danger-border-color;
}

.alert-warning {
  background-color: $alert-warning-bg;
  border-color: $alert-warning-border-color;
}

.alert-info {
  background-color: $alert-info-bg;
  border-color: $alert-info-border-color;
  .title {
    font-weight: 500;
    margin-bottom: 2px;
    color: $text-icon-dark;
  }
  a {
    color: $primary;
    font-weight: normal;
  }
  strong {
    font-weight: 500;
    color: $text-icon-dark;
  }
}

.announcement {
  margin: auto;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  .body {
    flex-direction: column;
    justify-content: flex-start;
  }
  .close-btn {
    margin-top: -3px;
    font-size: 20px;
    color: $alert-announcement-close-btn;
  }
  .info-icon {
    margin-top: 15px;
  }
}

.dark {
  .alert {
    color: $alert-color-dark;
    background-color: $success-solid-dark-alert;
  }
  .alert-info {
    background-color: $info-solid-dark-alert;
    .title {
      color: $text-icon-light;
    }
    a {
      color: $primary;
    }
    strong {
      color: $text-icon-light;
    }
  }
  .alert-warning {
    background-color: $warning-solid-dark-alert;
  }
  .alert-danger{
    background-color: $danger-solid-dark-alert
  }
  .announcement {
    .close-btn {
      color: $alert-color-dark;
    }
  }
}
.text-info {
  color: $bt-brand-primary !important;
}
